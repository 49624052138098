import { gatewayApi } from "@/ReduxToolkit";
import { useLazyGetOfflineAssetsQuery } from "@/features/offline-use";
import { useUpdateUserMutation } from "@/features/user/api/endpoints";
import { camelCase } from "lodash";
import { DEFAULT_FILTER_KEYS } from "../api/useLazyAssetList";
import { useSetAssetListParams } from "../api/useAssetListParams";

const excludedStatuses = ["sold", "charge off", "back out"];

export const useLazyExcludedStatusIds = () => {
	const [queryTrigger] =
		gatewayApi.endpoints.getRemarketingStatuses.useLazyQuery();

	const trigger = async () => {
		const { data, error } = await queryTrigger(undefined, true);
		if (!error) {
			const statuses = Object.entries(data)
				?.filter(([_id, description]) => {
					return excludedStatuses.includes(description.toLowerCase());
				})
				?.map(([id, _description]) => id);

			return {
				data: statuses,
			};
		}
		return [];
	};

	return [trigger];
};

export const useLazyDefaultFilters = () => {
	const [statusTrigger] = useLazyExcludedStatusIds();

	const trigger = async () => {
		const { data: excludedStatusIds } = await statusTrigger();
		return [
			{
				propertyPath: "remarketingStatusTypeId",
				operation: "!=",
				value: excludedStatusIds,
			},
		];
	};

	return [trigger];
};

export const useLazyMyAssetsFilters = () => {
	const [defaultTrigger] = useLazyDefaultFilters();

	const [currentUserTrigger] =
		gatewayApi.endpoints.getCurrentUser.useLazyQuery();

	const trigger = async () => {
		const defaultFilters = await defaultTrigger();
		const { data: currentUser } = await currentUserTrigger(undefined, true);

		return [
			...defaultFilters,
			{
				propertyPath: "account.assetManagerId",
				operation: "==",
				value: [currentUser?.id],
			},
		];
	};

	return [trigger];
};

export const useLazyOfflineAssetsFilters = () => {
	const [offlineAssetTrigger, result] = useLazyGetOfflineAssetsQuery({
		selectFromResult: ({ data }) => data?.ids ?? [],
	});

	const trigger = async () => {
		const { data: offlineAssets } = await offlineAssetTrigger();
		return [
			{
				propertyPath: "id",
				operation: "==",
				value: offlineAssets?.ids,
			},
		];
	};

	return [trigger, result];
};

export const useLazyFavoriteAssetsFilters = () => {
	const [currentUserTrigger, result] =
		gatewayApi.endpoints.getCurrentUser.useLazyQuery({
			selectFromResult: ({ data }) => data?.favoriteAssets ?? [],
		});

	const trigger = async () => {
		const { data: currentUser } = await currentUserTrigger(undefined, true);

		return [
			{
				propertyPath: "id",
				operation: "==",
				value: currentUser?.favoriteAssets,
			},
		];
	};

	return [trigger, result];
};

export const useLazySavedFilters = () => {
	const [getUserData] = gatewayApi.endpoints.getCurrentUser.useLazyQuery(
		undefined,
		{
			selectFromResult: ({ data }) => {
				return { data: data?.savedFilters ?? [] };
			},
		}
	);

	const { setParams } = useSetAssetListParams();
	const [updateUser] = useUpdateUserMutation();

	const addSavedFilters = async ({ name, filters }) => {
		const camelCaseName = camelCase(name);
		if (DEFAULT_FILTER_KEYS.includes(camelCaseName)) return;
		const { data } = await getUserData(undefined, false);
		await updateUser({
			savedFilters: { ...data?.savedFilters, [camelCaseName]: filters },
		});
	};

	const setDefaultView = async (name) => {
		await updateUser({
			defaultAssetListView: name,
		});
		setParams({ view: name });
	};

	const removeSavedFilter = async (name) => {
		const { data } = await getUserData(undefined, false);
		const update = Object.fromEntries(
			Object.entries(data.savedFilters).filter(([key, _value]) => key !== name)
		);
		await updateUser({
			savedFilters: update,
		});
	};

	const trigger = async () => {
		const { data } = await getUserData();
		return (
			data &&
			Object.fromEntries(
				Object.entries(data?.savedFilters)?.map(([key, value]) => {
					return [key, async () => value];
				})
			)
		);
	};

	return { trigger, addSavedFilters, setDefaultView, removeSavedFilter };
};
