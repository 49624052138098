export const defaultHomebaseData = {
	favoriteAssets: [],
	savedFilters: {},
	defaultAssetListView: "allAssets",
};

export const deserializeUser = (user) => {
	const { applicationData, ...rest } = user;
	const homebaseData = applicationData.find(
		(data) => data.applicationName === "Homebase"
	);
	const parsedHomebaseData = homebaseData?.data
		? JSON.parse(homebaseData.data)
		: {};

	const userData = {
		...defaultHomebaseData,
		...parsedHomebaseData,
	};

	return {
		...rest,
		...userData,
		applicationData,
	};
};

export const serializeUser = (user) => {
	const isAppDataKey = (key) => Object.keys(defaultHomebaseData).includes(key);
	const isHomebaseData = (data) => data.applicationName === "Homebase";

	const userProperties = Object.keys(user).filter((key) => !isAppDataKey(key));
	const appDataProperties = Object.keys(user).filter(isAppDataKey);

	const homebaseData = user.applicationData.find(isHomebaseData);
	const otherApplicationData = user.applicationData.filter(
		(key) => !isHomebaseData(key)
	);

	const userData = Object.fromEntries(
		userProperties.map((key) => [key, user[key]])
	);
	const updatedApplicationData = Object.fromEntries(
		appDataProperties.map((property) => [property, user[property]])
	);

	return {
		...userData,
		applicationData: [
			{
				...homebaseData,
				applicationName: "Homebase",
				data: JSON.stringify(updatedApplicationData),
			},
			...otherApplicationData,
		],
	};
};
